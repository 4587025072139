<template>
  <BaseModal :config="config">
    <template #content>
      <div class="game-block">
        <div class="game-block__header">
          <h2>Chặn Game</h2>
          <div class="close-btn" @click="close" />
        </div>
        <div class="game-block__body">
          <div class="warning">Không thể chơi game này!</div>
          <div class="text notif">
            <p>
              Quý khách cần hoàn thành số tiền cược yêu cầu
              <span>{{ $formatMoney(rollingValue || 0) }} Đ</span>
              của gói khuyến mãi
              <span>{{ store.plan?.name }}.</span>
            </p>
          </div>
          <div class="text notif">
            <p>Quý khách vui lòng chọn game khác hoặc huỷ khuyến mãi để tham gia trò chơi này.</p>
          </div>
          <div class="actions">
            <BaseButton class="base-button--btn-neutral" @click="close()">Quay lại</BaseButton>
            <BaseButton class="base-button--btn-primary" @click="openCancelPromotionPopup">Hủy Khuyến Mãi</BaseButton>
          </div>
        </div>
      </div>
    </template>
  </BaseModal>
  <ModalCancelPromotion :on-cancel="submitCancelPromotion" :is-loading-submit="isLoadingSubmit" />
</template>

<script setup lang="ts">
import { MODAL } from '~/constants/modal'
import { useCancelPromotion } from '~/composables/useCancelPromotion'
import { useAppStore } from '~~/store/app'
import { useAccountStore } from '~/store/account'
import { PAGE } from '~/constants/router'
import useModal from '~/composables/useModal'
import { useInitData } from '~~/composables/user/useInitData'
import { MODAL_TYPES } from '~/config/constant'
const ModalCancelPromotion = defineAsyncComponent(
  () => import('~/components/desktop/modals/cancel-promotion/modal-cancel-promotion.vue')
)
const BaseButton = defineAsyncComponent(() => import('~/components/common/base-button.vue'))
const BaseModal = defineAsyncComponent(() => import('~/components/common/base-modal.vue'))
const { $pinia, $bvModal } = useNuxtApp()
const store = useAccountStore($pinia)
const router = useRouter()
const { closeModal, openModalOne } = useModal()
const { fetchPlan } = useInitData()
const { openCancelPromotionPopup, submitCancelPromotion, isLoadingSubmit } = useCancelPromotion()
const config = reactive({
  id: MODAL_TYPES.BLOCK_GAME,
  centered: true,
  noCloseOnBackdrop: true,
  hideClose: true
})
const close = () => closeModal(MODAL_TYPES.BLOCK_GAME)
const goProfit = () => {
  close()
  router.push(`${PAGE.ACCOUNT_PROFIT_TODAY}`)
}
onMounted(() => {
  if (!store.plan) {
    fetchPlan()
  }
})
const rollingValue = computed(() => {
  const rolling = store.plan?.rolling ?? 0
  const turnover = store.plan?.turnover ?? 0
  return Math.max(Number(rolling) - Number(turnover), 0)
})
window.addEventListener('popstate', function () {
  closeModal(MODAL_TYPES.BLOCK_GAME)
})
</script>

<style scoped lang="scss" src="~/assets/scss/components/desktop/modals/block-game/index.scss"></style>
